<template>
  <div class="activity-outer">
    <br>
    <ATitle text="同期活动" icon="3"></ATitle>
    <br>
    <div class="flex activity-wrap center">
      <div class="activity" @click="goTo(item)" v-for="(item, index) in activityList" :key="index">
        <div class="flex img">
          <img :src="item.bannerUrl">
        </div>
        <div class="text tc"><span class="empty"></span><span class="textin">{{item.title}}</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activityList: [],
    }
  },
  created() {
    // this.$http.get('/meeting/activity/list',{
    // }).then(res => {
    //   if (res.code === 0) {
    //     this.activityList = res.data.filter(item => item.showStatus != 3)
    //   } else {
    //     this.$Message.warning(res.msg)
    //   }
    // }).catch(err => {
    //   this.$Message.error(err)
    // })
  },
  methods: {
    goTo(item) {
      if (item.showStatus === 4) {
        return
      }
      this.$router.push('/activity/'+item.id)
    }
  }
}
</script>

<style lang="less" scoped>
.activity-outer {
  min-height: calc(100vh - 450px);
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  padding-bottom: 40px;
}
.activity-wrap {
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 5px;
  width: 1080px;
  .activity {
    margin: 11px;
    display: block;
    cursor: pointer;
    width: 450px;
    height: 337px;
    border-radius: 4px;
    background: #F6F6F6;
    border: 1px solid #E4E4E4;
    .img {
      height: 252px;
      width: 100%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        transition: all ease-out 0.2s;
      }
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    .text {
      padding: 0 15px;
        font-size: 17px;
        color: #333;
        font-weight: 500px;
        height: 80px;
      .empty, .textin {
        display: inline-block;
        
        vertical-align: middle;
      }
      .empty {
        height: 100%;
      }
    }
  }
  .activity:hover {
    box-shadow: 0 0 7px #0769CC;
    img {
      transform: scale(1.05);
    }
    .text {
      color: #0769CC;
      font-weight: 600;
    }
  }
}
</style>
